import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { RiUploadCloudLine } from 'react-icons/ri';

import { storage } from 'utils';
import uploader from 'lib/uploader';

const DEFAULT_UPLOADER_END_POINT = import.meta.env.VITE_APP_UPLOADER_END_POINT;

const DEFAULT_API_BASE_URL = import.meta.env.VITE_APP_END_POINT;

const loading = (
  <svg
    className="animate-spin h-4 w-4 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

function ButtonUploader({
  api,
  name,
  icon,
  label,
  response,
  onChange,
  className,
  iconClassName,
  onSetFieldValue,
  prehook,
}) {
  const Icon = icon;

  const [isLoading, setIfLoading] = React.useState(false);

  const endpoint = api.startsWith(DEFAULT_API_BASE_URL)
    ? api
    : `${DEFAULT_API_BASE_URL}${api}`;
  // eslint-disable-next-line no-console
  // console.log(endpoint, api);

  const handleOnUpload = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-console
    // console.log(endpoint, api);
    uploader?.openDialog({
      api: endpoint,
      response,
      prehook,
      token: storage.get('_token'),
      progress: (i) => {
        setIfLoading(true);
        if (i === 100) {
          setIfLoading(false);
        }
      },
      callBack: (res) => {
        if (typeof onSetFieldValue === 'function') {
          onSetFieldValue(name, res);
        }
        if (typeof onChange === 'function') {
          onChange((prev) => ({
            ...prev,
            [name]: res,
          }));
        }
      },
    });
  };

  return (
    <button
      type="button"
      onClick={handleOnUpload}
      className={cn('flex gap-2 flex-shrink-0', {
        [className]: className,
        'btn md primary': !className,
      })}
    >
      {!isLoading ? <Icon className={iconClassName} /> : loading}
      {label}
    </button>
  );
}

ButtonUploader.defaultProps = {
  api: DEFAULT_UPLOADER_END_POINT,
  onChange: false,
  className: false,
  response: false,
  label: 'Upload File',
  onSetFieldValue: false,
  icon: RiUploadCloudLine,
  iconClassName: 'h-5 w-5',
  prehook: false,
};

ButtonUploader.propTypes = {
  api: PropTypes.string,
  label: PropTypes.string,
  response: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  iconClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  prehook: PropTypes.instanceOf(Function),
};

export default ButtonUploader;
